@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


* {
  font-family: "Ubuntu", sans-serif;
}


html,
body {
  position: relative;
  overflow: hidden;
  height: 100%;
}


body {
  font-family: 'Ubuntu', sans-serif;
  color: #0a0a0a;
  margin: 0 auto;
}



#root {
  @apply w-full  my-0  bg-gray-100 bg-opacity-60 h-full flex flex-1 items-start
}
.rs-sidenav-nav {
  background-color: white;
}
.rs-sidenav-nav a {
  background-color: white;
}
 .rs-sidenav-item, .rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: white !important;
}

.nop {
  font-family: "Inconsolata", monospace !important;
  font-weight: 500;
}


/* Animasi untuk memutar angka ke atas */
@keyframes spin-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.animate-spin-up {
  animation: spin-up 1s infinite linear;
}


.char {
  padding: 0 10px;
  background: #eee;
}
.char + .char {
  margin-left: 5px;
}
.sep {
  padding: 0 2px;
}

.icons {
  display: inline-block;
  width: 48px;
  height: 211.5px;
  overflow: hidden;
  will-change: backgroundPosition;
  transition: 0.3s background-position ease-in-out;
  padding: 0 24px;
  transform: translateZ(0);

}

.clipmask {
  clip-path: inset(30px 0 round 40px);
}


.border-clip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: transparent; /* Warna untuk border */
  z-index: 1;
}

.border-clip .border {
height: 100%;
  background: transparent; /* Warna untuk border */
  border: 10px solid #FFFFFF; /* Warna dan ketebalan border */
  border-radius: 40px; /* Pastikan ini sesuai dengan clip-path */
}

.animate-bounce {
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}


.gelatine {
  animation: gelatine 0.5s 2 forwards;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

.rs-modal-body {
  margin: 0 !important;
  padding: 0 !important;
  max-height: 920px !important;
  overflow: hidden;
}

.rs-modal-content {
  
  margin: 0 !important;
  padding: 0 !important   
}

.mulish {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.number-ready {
  @apply bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-yellow-400 from-0% to-orange-400  to-70%  text-white shadow-lg 
}

.number-win {
  @apply bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-yellow-200 from-0% to-yellow-400  to-70%  text-black
}

.rs-modal-sm {
  max-width: 512px !important;
}